// import React, { useState, useEffect } from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Grid,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   TextField,
//   Box,
// } from "@mui/material";
// import { Edit, Delete, Visibility } from "@mui/icons-material";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import { BASE_URL } from "../../services/configUrls";
// import MDButton from "components/MDButton";

// const ModuleDocuments = () => {
//   const [documents, setDocuments] = useState([]);
//   const [selectedDoc, setSelectedDoc] = useState(null);
//   const [openEdit, setOpenEdit] = useState(false);
//   const [openView, setOpenView] = useState(false);
//   const [openDelete, setOpenDelete] = useState(false);
//   const [editForm, setEditForm] = useState({
//     title: "",
//     subtitle: "",
//     content: "",
//   });

//   useEffect(() => {
//     fetchDocuments();
//   }, []);

//   const fetchDocuments = async () => {
//     try {
//       const response = await fetch(`${BASE_URL}/auth/allmodule_documents`);
//       const data = await response.json();
//       setDocuments(data.data);
//     } catch (error) {
//       console.error("Error fetching documents:", error);
//     }
//   };

//   const handleEdit = (doc) => {
//     setSelectedDoc(doc);
//     setEditForm({
//       title: doc.title,
//       subtitle: doc.subtitle,
//       content: doc.content,
//     });
//     setOpenEdit(true);
//   };

//   const handleView = (doc) => {
//     setSelectedDoc(doc);
//     setOpenView(true);
//   };

//   const handleDelete = (doc) => {
//     setSelectedDoc(doc);
//     setOpenDelete(true);
//   };

//   const handleEditSubmit = async () => {
//     try {
//       await fetch(`${BASE_URL}/auth/update_module/${selectedDoc.id}`, {
//         method: "PUT",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(editForm),
//       });
//       setOpenEdit(false);
//       fetchDocuments();
//     } catch (error) {
//       console.error("Error updating document:", error);
//     }
//   };

//   const handleDeleteConfirm = async () => {
//     try {
//       await fetch(`${BASE_URL}/auth/delete_module/${selectedDoc.id}`, {
//         method: "DELETE",
//       });
//       setOpenDelete(false);
//       fetchDocuments();
//     } catch (error) {
//       console.error("Error deleting document:", error);
//     }
//   };

//   return (
//     <>
//       <DashboardLayout>
//         <DashboardNavbar />
//         <Grid container spacing={3}>
//           {documents.map((doc) => (
//             <Grid item xs={12} sm={6} md={4} key={doc.id}>
//               <Card
//                 sx={{
//                   height: "100%",
//                   display: "flex",
//                   flexDirection: "column",
//                   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//                   transition: "transform 0.2s",
//                   "&:hover": {
//                     transform: "translateY(-4px)",
//                     boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
//                   },
//                 }}
//               >
//                 <CardContent sx={{ flexGrow: 1 }}>
//                   <Typography variant="h6" gutterBottom sx={{ color: "#1a237e" }}>
//                     {doc.title}
//                   </Typography>
//                   <Typography variant="subtitle2" color="text.secondary" gutterBottom>
//                     Module Code: {doc.module_code}
//                   </Typography>
//                   <Typography variant="body2" sx={{ mb: 2 }}>
//                     {doc.subtitle}
//                   </Typography>
//                   <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
//                     <IconButton
//                       size="small"
//                       onClick={() => handleView(doc)}
//                       sx={{ color: "#2196f3" }}
//                     >
//                       <Visibility />
//                     </IconButton>
//                     <IconButton
//                       size="small"
//                       onClick={() => handleEdit(doc)}
//                       sx={{ color: "#4caf50" }}
//                     >
//                       <Edit />
//                     </IconButton>
//                     <IconButton
//                       size="small"
//                       onClick={() => handleDelete(doc)}
//                       sx={{ color: "#f44336" }}
//                     >
//                       <Delete />
//                     </IconButton>
//                   </Box>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>

//         {/* View Dialog */}
//         <Dialog open={openView} onClose={() => setOpenView(false)} maxWidth="md" fullWidth>
//           <DialogTitle>{selectedDoc?.title}</DialogTitle>
//           <DialogContent>
//             <Typography variant="subtitle1" gutterBottom>
//               {selectedDoc?.subtitle}
//             </Typography>
//             <Typography variant="body1">{selectedDoc?.content}</Typography>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setOpenView(false)}>Close</Button>
//           </DialogActions>
//         </Dialog>

//         {/* Edit Dialog */}
//         <Dialog open={openEdit} onClose={() => setOpenEdit(false)} maxWidth="md" fullWidth>
//           <DialogTitle>Edit Document</DialogTitle>
//           <DialogContent>
//             <TextField
//               fullWidth
//               label="Title"
//               value={editForm.title}
//               onChange={(e) => setEditForm({ ...editForm, title: e.target.value })}
//               margin="normal"
//             />
//             <TextField
//               fullWidth
//               label="Subtitle"
//               value={editForm.subtitle}
//               onChange={(e) => setEditForm({ ...editForm, subtitle: e.target.value })}
//               margin="normal"
//             />
//             <TextField
//               fullWidth
//               label="Content"
//               value={editForm.content}
//               onChange={(e) => setEditForm({ ...editForm, content: e.target.value })}
//               margin="normal"
//               multiline
//               rows={4}
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setOpenEdit(false)}>Cancel</Button>
//             <MDButton onClick={handleEditSubmit} variant="contained" color="info">
//               Save Changes
//             </MDButton>
//           </DialogActions>
//         </Dialog>

//         {/* Delete Confirmation Dialog */}
//         <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
//           <DialogTitle>Confirm Delete</DialogTitle>
//           <DialogContent>
//             <Typography>Are you sure you want to delete "{selectedDoc?.title}"?</Typography>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
//             <MDButton onClick={handleDeleteConfirm} variant="contained" color="error">
//               Delete
//             </MDButton>
//           </DialogActions>
//         </Dialog>
//       </DashboardLayout>
//     </>
//   );
// };

// export default ModuleDocuments;

import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Container,
  Divider,
  CircularProgress,
  Alert,
  Snackbar,
  InputAdornment,
  Paper,
  Checkbox,
} from "@mui/material";
import { Edit, Delete, Visibility, Search, Clear } from "@mui/icons-material";
import { BASE_URL } from "../../services/configUrls";

const ModuleDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [openBulkDelete, setOpenBulkDelete] = useState(false);
  const [editForm, setEditForm] = useState({
    title: "",
    subtitle: "",
    content: "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    filterDocuments();
  }, [searchTerm, documents]);

  const filterDocuments = () => {
    const filtered = documents.filter((doc) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        doc.module_name?.toLowerCase().includes(searchTermLower) ||
        doc.course_name?.toLowerCase().includes(searchTermLower) ||
        doc.title?.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredDocuments(filtered);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/auth/allmodule_documents`);

      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }

      const data = await response.json();
      setDocuments(data.data);
      setFilteredDocuments(data.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setError("Failed to load documents. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (doc) => {
    setSelectedDoc(doc);
    setEditForm({
      title: doc.title,
      subtitle: doc.subtitle,
      content: doc.content,
    });
    setOpenEdit(true);
  };

  const handleView = (doc) => {
    setSelectedDoc(doc);
    setOpenView(true);
  };

  const handleDelete = (doc) => {
    setSelectedDoc(doc);
    setOpenDelete(true);
  };

  const handleEditSubmit = async () => {
    try {
      const response = await fetch(`${BASE_URL}/auth/update_module/${selectedDoc.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(editForm),
      });

      if (!response.ok) {
        throw new Error("Failed to update document");
      }

      setSnackbar({
        open: true,
        message: "Document updated successfully",
        severity: "success",
      });
      setOpenEdit(false);
      fetchDocuments();
    } catch (error) {
      console.error("Error updating document:", error);
      setSnackbar({
        open: true,
        message: "Failed to update document",
        severity: "error",
      });
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`${BASE_URL}/auth/delete_module/${selectedDoc.id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete document");
      }

      setSnackbar({
        open: true,
        message: "Document deleted successfully",
        severity: "success",
      });
      setOpenDelete(false);
      fetchDocuments();
    } catch (error) {
      console.error("Error deleting document:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete document",
        severity: "error",
      });
    }
  };

  const handleBulkDelete = async () => {
    try {
      const responses = await Promise.all(
        selectedDocs.map((docId) =>
          fetch(`${BASE_URL}/auth/delete_module/${docId}`, {
            method: "DELETE",
          })
        )
      );

      if (responses.every((response) => response.ok)) {
        setSnackbar({
          open: true,
          message: "Selected documents deleted successfully",
          severity: "success",
        });
        setSelectedDocs([]);
      } else {
        throw new Error("Some documents failed to delete");
      }
    } catch (error) {
      console.error("Error in bulk delete:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete some documents",
        severity: "error",
      });
    } finally {
      setOpenBulkDelete(false);
      fetchDocuments();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Add these functions
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedDocs(filteredDocuments.map((doc) => doc.id));
    } else {
      setSelectedDocs([]);
    }
  };

  const handleSelectDoc = (docId) => {
    setSelectedDocs((prev) =>
      prev.includes(docId) ? prev.filter((id) => id !== docId) : [...prev, docId]
    );
  };

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "60vh",
            }}
          >
            <CircularProgress />
          </Box>
        </Container>
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Alert severity="error">{error}</Alert>
        </Container>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" component="h1" sx={{ color: "#1a237e" }}>
            Module Documents
          </Typography>
        </Box>

        <Paper
          elevation={3}
          sx={{
            p: 2,
            mb: 3,
            borderRadius: "12px",
            backgroundColor: "#f8f9fa",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by module name, course name, or title..."
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="action" />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch} size="small">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "white",
                },
              },
            }}
          />
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={selectedDocs.length === filteredDocuments.length}
              indeterminate={
                selectedDocs.length > 0 && selectedDocs.length < filteredDocuments.length
              }
              onChange={handleSelectAll}
              sx={{
                "& .MuiSvgIcon-root": {
                  border: "2px solid #1976d2",
                  borderRadius: "4px",
                  width: 24,
                  height: 24,
                  backgroundColor: "white",
                  "&:hover": {
                    borderColor: "#1565c0",
                  },
                },
              }}
            />
            <Typography variant="body2" sx={{ mr: 2 }}>
              {selectedDocs.length} selected
            </Typography>
          </Box>
          {selectedDocs.length > 0 && (
            <MDButton color="error" onClick={() => setOpenBulkDelete(true)} startIcon={<Delete />}>
              Delete Selected
            </MDButton>
          )}
        </Box>

        <Grid container spacing={3}>
          {filteredDocuments.map((doc) => (
            <Grid item xs={12} sm={6} md={4} key={doc.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, position: "relative" }}>
                  <Box sx={{ position: "absolute", top: 8, right: 8 }}>
                    <Checkbox
                      checked={selectedDocs.includes(doc.id)}
                      onChange={() => handleSelectDoc(doc.id)}
                      onClick={(e) => e.stopPropagation()}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          border: "2px solid #1976d2",
                          borderRadius: "4px",
                          width: 24,
                          height: 24,
                          backgroundColor: "white",
                          "&:hover": {
                            borderColor: "#1565c0",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#1a237e", minHeight: "64px" }}
                  >
                    {doc.title}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Course: {doc.course_name}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Module: {doc.module_name}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 2,
                      minHeight: "48px",
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {doc.subtitle}
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                    <IconButton
                      size="small"
                      onClick={() => handleView(doc)}
                      sx={{ color: "#2196f3" }}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleEdit(doc)}
                      sx={{ color: "#4caf50" }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(doc)}
                      sx={{ color: "#f44336" }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* View Dialog */}
        <Dialog
          open={openView}
          onClose={() => setOpenView(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: { borderRadius: "8px", boxShadow: "0 8px 16px rgba(0,0,0,0.1)" },
          }}
        >
          <DialogTitle
            sx={{
              color: "#1a237e",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              pb: 2,
            }}
          >
            {selectedDoc?.title}
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Course: {selectedDoc?.course_name}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Module: {selectedDoc?.module_name}
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="subtitle1" gutterBottom sx={{ color: "#424242", fontWeight: 500 }}>
              {selectedDoc?.subtitle}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#616161", lineHeight: 1.7, whiteSpace: "pre-wrap" }}
            >
              {selectedDoc?.content}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
            <MDButton color="info" onClick={() => setOpenView(false)}>
              Close
            </MDButton>
          </DialogActions>
        </Dialog>

        {/* Edit Dialog */}
        <Dialog
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: { borderRadius: "8px" },
          }}
        >
          <DialogTitle sx={{ color: "#1a237e" }}>Edit Document</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Course: {selectedDoc?.course_name}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Module: {selectedDoc?.module_name}
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <TextField
              fullWidth
              label="Title"
              value={editForm.title}
              onChange={(e) => setEditForm({ ...editForm, title: e.target.value })}
              margin="normal"
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Subtitle"
              value={editForm.subtitle}
              onChange={(e) => setEditForm({ ...editForm, subtitle: e.target.value })}
              margin="normal"
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Content"
              value={editForm.content}
              onChange={(e) => setEditForm({ ...editForm, content: e.target.value })}
              margin="normal"
              variant="outlined"
              multiline
              rows={6}
            />
          </DialogContent>
          <DialogActions sx={{ p: 2, gap: 1 }}>
            <MDButton color="error" onClick={() => setOpenEdit(false)}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={handleEditSubmit}>
              Save Changes
            </MDButton>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          PaperProps={{
            sx: { borderRadius: "8px" },
          }}
        >
          <DialogTitle sx={{ color: "#1a237e" }}>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete "{selectedDoc?.title}"?</Typography>
          </DialogContent>
          <DialogActions sx={{ p: 2, gap: 1 }}>
            <MDButton color="error" onClick={() => setOpenDelete(false)}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={handleDeleteConfirm}>
              Delete
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openBulkDelete}
          onClose={() => setOpenBulkDelete(false)}
          PaperProps={{
            sx: { borderRadius: "8px" },
          }}
        >
          <DialogTitle sx={{ color: "#1a237e" }}>Confirm Bulk Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete {selectedDocs.length} selected documents?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 2, gap: 1 }}>
            <MDButton color="error" onClick={() => setOpenBulkDelete(false)}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={handleBulkDelete}>
              Delete
            </MDButton>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        {documents.length === 0 && !loading && !error && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
              bgcolor: "#f5f5f5",
              borderRadius: "8px",
              mt: 4,
            }}
          >
            <Typography variant="h6" color="text.secondary">
              No documents available
            </Typography>
          </Box>
        )}
        {filteredDocuments.length === 0 && !loading && !error && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
              bgcolor: "#f5f5f5",
              borderRadius: "8px",
              mt: 4,
            }}
          >
            <Typography variant="h6" color="text.secondary">
              {searchTerm ? "No matching documents found" : "No documents available"}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ModuleDocuments;
