import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  useMediaQuery,
  Paper,
  Card,
  CardMedia,
  CardContent,
  useTheme,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { UserService } from "services/service";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ContentSection, StyledSection } from "./EditSection";

const SplitDescription = ({ description }) => {
  const containsHTML = /<[^>]*>/g.test(description);

  if (containsHTML) {
    return (
      <Box sx={{ "& > *": { mb: 2 } }}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Box>
    );
  }

  const sentences = description
    .split(".")
    .filter((s) => s.trim())
    .map((s) => s.trim());

  return sentences.length <= 1 ? (
    <Typography variant="body1" color="text.secondary">
      {description}
    </Typography>
  ) : (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {sentences.map((sentence, i) => (
        <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ mr: 1, color: "primary.main" }}>
            •
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500, color: "text.secondary" }}>
            {sentence}.
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

// const StyledSection = ({ section }) => (
//   <Box sx={{ mb: 3 }}>
//     <Paper
//       elevation={0}
//       sx={{
//         p: 2,
//         background: "linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%)",
//         borderRadius: "10px",
//         mb: 2,
//       }}
//     >
//       <Typography
//         variant="h5"
//         sx={{
//           fontWeight: 600,
//           color: "#1565C0",
//           textShadow: "1px 1px 1px rgba(0,0,0,0.1)",
//           letterSpacing: "0.5px",
//           position: "relative",
//           "&::after": {
//             content: '""',
//             position: "absolute",
//             bottom: -8,
//             left: 0,
//             width: "60px",
//             height: "3px",
//             backgroundColor: "#1976D2",
//             borderRadius: "2px",
//           },
//         }}
//       >
//         {section.title}
//       </Typography>
//     </Paper>
//     <SplitDescription description={section.description} />
//   </Box>
// );

const ModuleViewer = ({
  moduleDocument,
  title,
  description,
  setCurrentIndex,
  isCompleted,
  courseCode,
  moduleCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [timer, setTimer] = useState(20);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [completedPages, setCompletedPages] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const storageKey = `${courseCode}_${moduleCode}_completedPages`;
  const totalPages = moduleDocument.totalPages;
  const currentContent = moduleDocument.pages[currentPage - 1];

  useEffect(() => {
    const storedCompletedPages = JSON.parse(localStorage.getItem(storageKey) || "[]");
    setCompletedPages(storedCompletedPages);
  }, [storageKey]);

  useEffect(() => {
    if (completedPages.length > 0) {
      localStorage.setItem(storageKey, JSON.stringify(completedPages));
    }
  }, [completedPages, storageKey]);

  useEffect(() => {
    let timerInterval;
    if (isTimerRunning) {
      timerInterval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(timerInterval);
            setIsTimerRunning(false);
            setCompletedPages((prev) => {
              const newCompletedPages = [...prev, currentPage];
              localStorage.setItem(storageKey, JSON.stringify(newCompletedPages));
              return newCompletedPages;
            });
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [isTimerRunning, currentPage, storageKey]);

  useEffect(() => {
    if (currentPage <= totalPages && !completedPages.includes(currentPage)) {
      setTimer(10);
      setIsTimerRunning(true);
    } else {
      setTimer(0);
      setIsTimerRunning(false);
    }
  }, [currentPage, totalPages, completedPages]);

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (isCompleted) {
      setCurrentIndex((prev) => prev + 1);
    } else if (moduleCode && courseCode) {
      try {
        setLoading(true);
        await UserService.progress({
          courseCode,
          moduleCode,
          isDocumentComplete: 1,
          isVideoComplete: 1,
          isQuestionComplete: 0,
          isFinalQuestionComplete: 0,
          record_status: 1,
          opType: "UPDATE_PROGRESS",
        });
        setCurrentIndex((prev) => prev + 1);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const shouldRenderImage = (section) => {
    if (!section?.image) return false;
    const isBase64 = /^data:image\/[a-z]+;base64,/.test(section.image);
    const isURL = /^https?:\/\/.+/.test(section.image);
    return isBase64 || isURL;
  };

  return (
    <MDBox py={0} px={3}>
      <Box sx={{ height: "calc(100vh - 100px)", display: "flex", flexDirection: "column", gap: 2 }}>
        {/* <Box>
          <Typography
            variant="h4"
            fontSize={isSmallScreen ? 16 : 18}
            gutterBottom
            sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            {title}
          </Typography>
          <Typography variant="body1" fontSize={14} sx={{ color: theme.palette.text.secondary }}>
            {description}
          </Typography>
        </Box> */}

        <Paper
          elevation={3}
          sx={{
            flex: 1,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            bgcolor: theme.palette.background.paper,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{ p: 2, borderBottom: `1px solid ${theme.palette.divider}`, bgcolor: "#1A73E8" }}
          >
            <Typography variant="h5" align="center" sx={{ fontWeight: 500, color: "#ffffff" }}>
              {currentContent.title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{ fontWeight: 400, color: "#ffffff" }}
            >
              {currentContent.subtitle}
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              overflowX: "hidden",
              p: 3,
              "&::-webkit-scrollbar": { width: "6px", height: "0px" },
              "&::-webkit-scrollbar-track": { bgcolor: "background.paper" },
              "&::-webkit-scrollbar-thumb": {
                bgcolor: "#1A73E8",
                borderRadius: "3px",
              },
            }}
          >
            {/* <Typography variant="body1" paragraph sx={{ mb: 3, lineHeight: 1.8 }}>
              {currentContent.content}
            </Typography> */}
            <ContentSection content={currentContent.content} />

            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              {currentContent.sections.map((section, index) => (
                <Card
                  key={index}
                  elevation={1}
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": { transform: "translateY(-2px)" },
                  }}
                >
                  <CardContent sx={{ p: 5 }}>
                    {/* <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: 500, color: "#1A73E8" }}
                    >
                      {section.title}
                    </Typography> */}
                    <StyledSection section={section} />

                    {/* <SplitDescription description={section.description} /> */}
                    {/* <StyledSection section={section} /> */}

                    {shouldRenderImage(section) && (
                      <>
                        <CardMedia
                          component="img"
                          image={section.image}
                          alt={section.title}
                          sx={{
                            borderRadius: 1,
                            maxHeight: 300,
                            objectFit: "cover",
                          }}
                        />
                        <Typography
                          variant="caption"
                          align="center"
                          sx={{
                            display: "block",
                            mt: 1,
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {section.caption}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        </Paper>

        <Box sx={{ mt: "auto" }}>
          <Box sx={{ display: "flex", gap: 0.5, mb: 2 }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <Box
                key={index}
                sx={{
                  flex: 1,
                  height: 4,
                  bgcolor:
                    currentPage > index ? theme.palette.success.main : theme.palette.grey[300],
                  borderRadius: 1,
                  transition: "background-color 0.3s ease",
                }}
              />
            ))}
          </Box>

          <Box
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isTimerRunning && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <CircularProgress
                    variant="determinate"
                    color="info"
                    value={(timer / 10) * 100}
                    size={24}
                  />
                  <Typography variant="body2">{timer}s</Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <MDButton
                size="small"
                color="info"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </MDButton>
              <MDButton
                size="small"
                color="info"
                onClick={handleNextPage}
                disabled={loading || (isTimerRunning && !completedPages.includes(currentPage))}
              >
                {loading ? "Loading..." : currentPage < totalPages ? "Next" : "Complete"}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </MDBox>
  );
};

ModuleViewer.propTypes = {
  moduleDocument: PropTypes.shape({
    totalPages: PropTypes.number.isRequired,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            image: PropTypes.string,
            caption: PropTypes.string,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isCompleted: PropTypes.any,
  courseCode: PropTypes.number.isRequired,
  moduleCode: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
};

SplitDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ModuleViewer;
