import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import {
  TextField,
  Button,
  Container,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { BASE_URL } from "../../services/configUrls";
import { UserService } from "../../services/service";

const ModuleAdd = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [availableModules, setAvailableModules] = useState([]);
  const [modules, setModules] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [currentModule, setCurrentModule] = useState({
    title: "",
    subtitle: "",
    content: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${BASE_URL}/auth/get_allcourses`);
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();

        // Transform the API data to match the required format for courses
        const formattedCourses = Array.isArray(data.data)
          ? data.data.map((course) => ({
              courseName: course.course_name,
              courseCode: course.course_code,
              label: `${course.course_name} (${course.course_code})`,
            }))
          : [];

        setCourses(formattedCourses);
        setError(null);
      } catch (err) {
        setError(err.message);
        setSnackbar({
          open: true,
          message: "Failed to load courses. Please try again later.",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  // Fetch available modules from API
  useEffect(() => {
    const fetchModules = async () => {
      try {
        setLoading(true);
        // const response = await fetch(`${BASE_URL}/auth/getMainModule`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });

        const response = await UserService.getModuleForAdd(selectedCourse.courseCode);
        // console.log(response);

        // if (!response.ok) {
        //   throw new Error("Failed to fetch modules");
        // }

        const data = response.data;
        // console.log(data, "data");

        // Transform the API data to match the required format
        const formattedModules = data.map((module) => ({
          moduleName: module.module_name,
          moduleCode: module.module_code,
          label: `${module.module_name} (${module.module_code})`,
        }));

        setAvailableModules(formattedModules);
        setError(null);
      } catch (err) {
        setError(err.message);
        setSnackbar({
          open: true,
          message: "Failed to load modules. Please try again later.",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    if (selectedCourse) {
      fetchModules();
    }
  }, [selectedCourse]);

  useEffect(() => {
    const savedModules = localStorage.getItem("modules");
    if (savedModules) {
      setModules(JSON.parse(savedModules));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("modules", JSON.stringify(modules));
  }, [modules]);

  const handleCourseSelect = (event, newValue) => {
    setSelectedCourse(newValue);
    setSelectedModule(null);
    setModules([]);
  };

  const handleModuleSelect = (event, newValue) => {
    setSelectedModule(newValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentModule((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddModule = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentModule({ title: "", subtitle: "", content: "" });
  };

  const handleSaveModule = () => {
    if (!currentModule.title || !currentModule.subtitle || !currentModule.content) {
      setSnackbar({
        open: true,
        message: "Please fill in all fields",
        severity: "error",
      });
      return;
    }

    const newModule = {
      ...currentModule,
      id: Date.now(),
      moduleCode: selectedModule.moduleCode,
      moduleName: selectedModule.moduleName,
    };

    setModules((prevModules) => [...prevModules, newModule]);
    handleCloseDialog();
    setSnackbar({
      open: true,
      message: "Module added successfully",
      severity: "success",
    });
  };

  const handleDeleteModule = (moduleId) => {
    setModules((prevModules) => prevModules.filter((module) => module.id !== moduleId));
    setSnackbar({
      open: true,
      message: "Module deleted successfully",
      severity: "success",
    });
  };

  //   const handleSubmitAll = () => {
  //     const submissionData = {
  //       moduleCode: selectedModule.moduleCode,
  //       moduleName: selectedModule.moduleName,
  //       contents: modules,
  //     };
  //     console.log("All modules submitted:", submissionData);
  //     setSnackbar({
  //       open: true,
  //       message: "All modules submitted successfully",
  //       severity: "success",
  //     });
  //   };

  const handleSubmitAll = async () => {
    // Input validation
    if (!selectedModule?.moduleCode || !selectedModule?.moduleName) {
      setSnackbar({
        open: true,
        message: "Please select a module before submitting",
        severity: "error",
      });
      return;
    }

    if (!modules.length) {
      setSnackbar({
        open: true,
        message: "Please add at least one content item before submitting",
        severity: "error",
      });
      return;
    }

    // Set loading state
    setLoading(true);

    try {
      // Prepare submission data
      const submissionData = {
        module_code: selectedModule.moduleCode,
        module_name: selectedModule.moduleName,
        contents: modules.map(({ title, subtitle, content, moduleCode, moduleName }) => ({
          title,
          subtitle,
          content,
          module_code: moduleCode,
          module_name: moduleName,
        })),
      };

      // Make the API call
      const response = await fetch(
        `${BASE_URL}/auth/add_documents/?module_code=${encodeURIComponent(
          selectedModule.moduleCode
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any authentication headers if needed
            // Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(submissionData),
        }
      );

      // Handle non-OK responses
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Server responded with status: ${response.status}`);
      }

      // Parse response data
      const responseData = await response.json();

      // Clear form after successful submission
      setModules([]);
      localStorage.removeItem("modules");
      setSelectedModule(null);

      // Show success message
      setSnackbar({
        open: true,
        message: "Content submitted successfully",
        severity: "success",
      });

      return responseData; // Return response data for any additional handling
    } catch (error) {
      console.error("Error submitting modules:", error);

      // Handle specific error types
      let errorMessage = "Failed to submit modules. Please try again.";

      if (error.name === "TypeError" || error.message.includes("NetworkError")) {
        errorMessage = "Network error. Please check your connection.";
      } else if (error.message.includes("status: 401")) {
        errorMessage = "Authentication error. Please log in again.";
      } else if (error.message.includes("status: 413")) {
        errorMessage = "Content too large. Please reduce the size of your submission.";
      }

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: "error",
      });

      throw error; // Re-throw for additional error handling if needed
    } finally {
      setLoading(false);
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography sx={{ textAlign: "center", mt: 4 }}>
            <CircularProgress />
          </Typography>
        </Container>
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Alert severity="error">Error loading modules: {error}</Alert>
        </Container>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mb: 4,
            backgroundColor: "#ffffff",
          }}
        >
          <Typography variant="h5" component="h1" sx={{ color: "#1a237e", mb: 3 }}>
            Add Content to Course
          </Typography>
          <Autocomplete
            id="course-select"
            options={courses}
            getOptionLabel={(option) => option?.label || ""}
            value={selectedCourse}
            onChange={handleCourseSelect}
            renderInput={(params) => (
              <TextField {...params} label="Select Course" variant="outlined" fullWidth />
            )}
            sx={{ mb: 2 }}
          />
          {selectedCourse && (
            <Autocomplete
              id="module-select"
              options={availableModules}
              getOptionLabel={(option) => option?.label || ""}
              value={selectedModule}
              onChange={handleModuleSelect}
              renderInput={(params) => (
                <TextField {...params} label="Select Module" variant="outlined" fullWidth />
              )}
            />
          )}
        </Paper>

        {selectedModule && (
          <Box
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}
          >
            <Typography variant="h4" component="h1" sx={{ color: "#1a237e" }}>
              Content for {selectedModule.moduleName}
            </Typography>
            <MDButton
              variant="contained"
              color="info"
              startIcon={<AddIcon />}
              onClick={handleAddModule}
            >
              Add Content
            </MDButton>
          </Box>
        )}

        <Box sx={{ mb: 4 }}>
          {modules.map((module) => (
            <Card
              key={module.id}
              sx={{
                mb: 2,
                backgroundColor: "#ffffff",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box>
                    <Typography variant="h6" gutterBottom sx={{ color: "#1a237e" }}>
                      {module.title}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "#546e7a" }} gutterBottom>
                      {module.subtitle}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#37474f" }}>
                      {module.content}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => handleDeleteModule(module.id)}
                    sx={{
                      color: "#f44336",
                      "&:hover": {
                        backgroundColor: "rgba(244, 67, 54, 0.04)",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>

        {modules.length > 0 && selectedModule && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <MDButton variant="contained" size="large" color="success" onClick={handleSubmitAll}>
              Submit All Content
            </MDButton>
          </Box>
        )}

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: { backgroundColor: "#ffffff" },
          }}
        >
          <DialogTitle sx={{ color: "#1a237e" }}>
            Add New Content for {selectedModule?.moduleName}
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              value={currentModule.title}
              onChange={handleChange}
              autoFocus
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="subtitle"
              label="Subtitle"
              name="subtitle"
              value={currentModule.subtitle}
              onChange={handleChange}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="content"
              label="Content"
              name="content"
              value={currentModule.content}
              onChange={handleChange}
              multiline
              rows={6}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} sx={{ color: "#757575" }}>
              Cancel
            </Button>
            <MDButton onClick={handleSaveModule} variant="contained" color="info">
              Save Content
            </MDButton>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{
              backgroundColor: snackbar.severity === "success" ? "#4caf50" : "#f44336",
              color: "#ffffff",
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </DashboardLayout>
  );
};

export default ModuleAdd;
