import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Icon, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CourseCard from "./CourseCard";
import PhotoCard from "./PhotoCard";
import CourseModuleCard from "./CourseModuleCard";
import BulkQuestionUploadCard from "./BulkQuestionUploadCard";
import PDFUploadCard from "./PDFUploadCard";
import VideoCard from "./VideoCard";
import { AdminService } from "services/service";
import { handleSuccessMessage } from "services/service";
import { handleErrMessage } from "services/service";
import { handleWarnMessage } from "services/service";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useNavigate } from "react-router-dom";
import CertificateCard from "./CertificateCard";

function AddCourse() {
  const existingCourse = JSON.parse(localStorage.getItem("editCourse")) || null;
  const [category, setCategory] = useState(existingCourse?.categoryName || null);
  const [categoryCode, setCategoryCode] = useState(existingCourse?.categoryCode || null);
  const [courseTitle, setCourseTitle] = useState(existingCourse?.courseName || "");

  const [courseDescription, setCourseDescription] = useState(existingCourse?.description || "");
  const [photoUrl, setPhotoUrl] = useState(existingCourse?.coursePhoto || "");
  const [currentModule, setCurrentModule] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [questionType, setQuestionType] = useState("");
  const [csvFile, setCsvFile] = useState(existingCourse?.questions || null);
  const [completedFields, setCompletedFields] = useState(0);
  const [isChapterCreation, setIsChapterCreation] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [isPublish, setIsPublish] = useState(() => {
    if (isChapterCreation) {
      return currentModule?.recordStatus ?? 0;
    } else {
      return existingCourse?.recordStatus ?? 0;
    }
  });
  const [moduleLoading, setModuleLoading] = useState(false);

  const [moduleTitle, setModuleTitle] = useState(currentModule?.moduleName || "");
  const [moduleDescription, setModuleDescription] = useState(
    currentModule?.moduleDescription || ""
  );
  const [questionTypeSm, setQuestionTypeSm] = useState("");
  const [csvFileSm, setCsvFileSm] = useState(currentModule?.moduleQuestion || null);
  const [pdfUrl, setPdfUrl] = useState(currentModule?.moduleDocument || null);
  const [videoUrl, setVideoUrl] = useState(currentModule?.moduleVideo || null);
  const [completedFieldsModule, setCompletedFieldsModule] = useState(0);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      isChapterCreation &&
      currentModule?.moduleName &&
      currentModule?.moduleDescription &&
      currentModule?.moduleDocument &&
      currentModule?.moduleQuestion
    ) {
      setModuleTitle(currentModule?.moduleName);
      setModuleDescription(currentModule?.moduleDescription);
      setPdfUrl(currentModule?.moduleDocument);
      setVideoUrl(currentModule?.moduleVideo);
      setCsvFileSm(currentModule?.moduleQuestion);
    }
  }, [currentModule?.moduleName, isChapterCreation]);

  useEffect(() => {
    return () => {
      // This will run when the component unmounts
      localStorage.removeItem("editCourse");
    };
  }, []);

  const handleAddModule = (module) => {
    // setModules([...modules, module]);
    setCurrentModule({});
    setModuleTitle("");
    setModuleDescription("");
    setPdfUrl(null);
    setVideoUrl(null);
    setCsvFileSm(null);
  };

  useEffect(() => {
    const fields = [
      category,
      courseTitle,
      courseDescription,
      photoUrl,
      moduleList.length > 0,
      csvFile,
    ];
    const filledFields = fields.filter((field) =>
      typeof field === "string" ? field.trim() !== "" : field
    );
    setCompletedFields(filledFields.length);
  }, [category, courseTitle, courseDescription, photoUrl, moduleList, csvFile]);

  useEffect(() => {
    const fields = [moduleTitle, moduleDescription, csvFileSm, videoUrl];
    const filledFields = fields.filter((field) =>
      typeof field === "string" ? field.trim() !== "" : field
    );
    setCompletedFieldsModule(filledFields.length);
  }, [moduleTitle, moduleDescription, csvFileSm, pdfUrl, videoUrl]);

  const addCourse = async () => {
    const formData = new FormData();
    formData.append("opType", existingCourse?.categoryCode ? "UPDATE_COURSE" : "ADD_COURSE");
    formData.append(
      "categoryCode",
      typeof category === "object" ? category?.categoryCode : categoryCode
    );
    formData.append(
      "courseName",
      typeof courseTitle === "object" ? courseTitle?.categoryName : courseTitle
    );
    // formData.append(
    //   "courseCode",
    //   typeof courseCode === "object" ? courseCode?.categoryCode : courseCode
    // );
    formData.append(
      "description",
      typeof courseDescription === "object" ? courseDescription?.categoryName : courseDescription
    );

    formData.append("recordStatus", isPublish);
    if (existingCourse?.courseCode) {
      formData.append("courseCode", existingCourse?.courseCode);
    }

    if (csvFile && existingCourse?.courseCode) {
      formData.append("question", csvFile);
    }

    if (photoUrl) {
      formData.append("image", photoUrl);
    }

    try {
      setIsLoading(true);
      const res = await AdminService.course(formData);
      setIsLoading(false);
      handleSuccessMessage(res.data.message);
      if (!existingCourse?.courseCode) {
        fetchTableData();
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const addModule = async () => {
    const formData = new FormData();
    formData.append("opType", currentModule?.moduleCode ? "UPDATE_MODULE" : "ADD_MODULE");
    formData.append(
      "moduleName",
      typeof moduleTitle === "object" ? moduleTitle.categoryName : moduleTitle
    );
    formData.append(
      "moduleDescription",
      typeof moduleDescription === "object" ? moduleDescription.categoryName : moduleDescription
    );

    formData.append("courseCode", existingCourse?.courseCode);
    if (currentModule?.moduleCode) {
      formData.append("moduleCode", currentModule?.moduleCode);
    }
    formData.append("recordStatus", isPublish);
    if (csvFileSm) {
      formData.append("moduleQuestion", csvFileSm);
    }
    if (pdfUrl) {
      formData.append("moduleDocument", pdfUrl);
    }
    if (videoUrl) {
      formData.append("moduleVideo", videoUrl);
    }

    if (existingCourse?.courseCode) {
      try {
        setIsLoading(true);
        const res = await AdminService.module(formData);
        console.warn("Load Done");
        handleSuccessMessage(res.data.message);
        setIsChapterCreation(false);
        fetchDataList();
        setCurrentModule({});
        setModuleTitle("");
        setModuleDescription("");
        setPdfUrl(null);
        setVideoUrl(null);
        setCsvFileSm(null);
        setIsLoading(false);
      } catch (error) {
        console.warn("Load Error");
        console.error("An error occurred while updating the module:", error);
        handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("Course Not Found");
      handleWarnMessage("Course not found. Please go back and select again.");
      setIsLoading(false);
    }
  };

  const fetchDataList = async () => {
    if (existingCourse?.courseCode) {
      const data = {
        courseCode: existingCourse?.courseCode,
      };
      try {
        setModuleLoading(true);
        const res = await AdminService.module(data);
        setModuleList(res.data?.responseData?.data);
        setModuleLoading(false);
      } catch (error) {
        console.error(error);
        setModuleLoading(false);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDataList();
  }, [existingCourse?.courseCode]);

  const handleDeleteConfirmation = async () => {
    const formData = new FormData();
    formData.append("opType", currentModule?.moduleCode ? "DELETE_MODULE" : "DELETE_COURSE");
    formData.append(
      currentModule?.moduleCode ? "moduleCode" : "courseCode",
      currentModule?.moduleCode ? currentModule?.moduleCode : existingCourse?.courseCode
    );
    try {
      setModuleLoading(true);
      const res = await AdminService[currentModule?.moduleCode ? "module" : "course"](formData);
      setModuleLoading(false);
      handleSuccessMessage(res.data.message);
      handleAddModule();
      setIsChapterCreation(false);
      setShowDeleteConfirmation(false); // Close the modal after successful deletion
      fetchDataList();

      if (!currentModule?.moduleCode) {
        navigate("/courses");
      }
    } catch (error) {
      console.error(error);
      setModuleLoading(false);
      handleErrMessage(error.response?.data?.message || "An unexpected error occurred.");
    }
  };

  const handleOpenDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const fetchTableData = async () => {
    try {
      // setIsLoading(true);
      const res = await AdminService.course({});
      setTableData(res.data?.responseData?.data);
      // setIsLoading(false);
    } catch (error) {
      console.error(error);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!existingCourse?.courseCode) {
      const fiterData = tableData.filter(
        (course) => course.courseName === courseTitle?.categoryName
      );
      if (fiterData[0]) {
        localStorage.setItem("editCourse", JSON.stringify(fiterData[0]));
        setTableData([]);
        window.location.reload();
      }
    }
  }, [tableData.length]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box lineHeight={1}>
          <Typography variant="h5">
            {isChapterCreation ? "Module Creation" : "Course Setup"}
          </Typography>
          <Typography variant="caption" fontWeight={500} color={"gray"}>{`Complete all fields (${
            isChapterCreation ? completedFieldsModule : completedFields
          }/${isChapterCreation ? 5 : 6})`}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {isChapterCreation && (
            <IconButton
              color="inherit"
              onClick={() => setIsChapterCreation(false)}
              sx={{ bgcolor: "lightgray", p: 0.5 }}
            >
              <Icon>arrow_back</Icon>
            </IconButton>
          )}
          <MDButton
            size="small"
            color="success"
            onClick={isChapterCreation ? addModule : addCourse}
            disabled={
              isLoading ||
              (isChapterCreation ? !(completedFieldsModule >= 3) : !(completedFields >= 4))
            }
          >
            {" "}
            {isLoading ? (
              <>
                <CircularProgress size={16} sx={{ mr: 0.5 }} color="inherit" />
                Loading...
              </>
            ) : (
              "Save"
            )}
          </MDButton>
          <MDButton
            size="small"
            color={isPublish === 1 ? "info" : "secondary"}
            disabled={
              (isChapterCreation ? !(completedFieldsModule >= 4) : !(completedFields === 6)) ||
              isLoading
            }
            onClick={() => setIsPublish(isPublish === 1 ? 0 : 1)}
          >
            {isPublish === 1 ? "Publish" : "UnPublish"}
          </MDButton>
          <IconButton
            disabled={!existingCourse?.courseCode}
            color="error"
            sx={{ bgcolor: "lightgray", p: 0.5 }}
            onClick={handleOpenDeleteConfirmation}
          >
            <Icon color="secondary">delete</Icon>
          </IconButton>
        </Box>
      </Box>
      {isChapterCreation ? (
        <MDBox pb={3} sx={{ minHeight: "67vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CourseCard
                title="Module Title"
                value={moduleTitle}
                setValue={setModuleTitle}
                type="Title"
              />
              <CourseCard
                title="Module Description"
                value={moduleDescription}
                setValue={setModuleDescription}
                type="Description"
              />
              <PDFUploadCard title="Module Document" pdfUrl={pdfUrl} setPdfUrl={setPdfUrl} />
              <BulkQuestionUploadCard
                title="Module Questions Upload"
                questionType={questionTypeSm}
                setQuestionType={setQuestionTypeSm}
                csvFile={csvFileSm}
                setCsvFile={setCsvFileSm}
                courseCode={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VideoCard title="Course Video" videoUrl={videoUrl} setVideoUrl={setVideoUrl} />
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox pb={3} sx={{ minHeight: "67vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {/* <CourseCard
                title="Course Code"
                value={courseCode}
                setValue={setCourseCode}
                type="code"
              /> */}
              <CourseCard
                title="Course Category"
                value={category}
                setValue={setCategory}
                setCategoryCode={setCategoryCode}
                type="Category"
              />
              <CourseCard
                title="Course Title"
                value={courseTitle}
                setValue={setCourseTitle}
                type="Title"
              />
              <CourseCard
                title="Course Description"
                value={courseDescription}
                setValue={setCourseDescription}
                type="Description"
              />
              <PhotoCard title="Course Photo" photoUrl={photoUrl} setPhotoUrl={setPhotoUrl} />
            </Grid>
            <Grid item xs={12} md={6}>
              <CourseModuleCard
                title={"Course Modules"}
                onAddModule={handleAddModule}
                setIsChapterCreation={setIsChapterCreation}
                moduleList={moduleList}
                moduleLoading={moduleLoading}
                setCurrentModule={setCurrentModule}
                courseCode={existingCourse?.courseCode}
              />
              <CertificateCard title={"Certificate"} courseCode={existingCourse?.courseCode} />
              <BulkQuestionUploadCard
                title="Final Questions Upload"
                questionType={questionType}
                setQuestionType={setQuestionType}
                csvFile={csvFile}
                setCsvFile={setCsvFile}
                courseCode={existingCourse?.courseCode}
              />
            </Grid>
          </Grid>
          <DeleteConfirmationModal
            open={showDeleteConfirmation} // Pass the state to control modal visibility
            onClose={handleCloseDeleteConfirmation} // Handler to close the modal
            onDelete={handleDeleteConfirmation} // Handler to confirm deletion
          />
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

AddCourse.propTypes = {
  existingCourse: PropTypes.shape({
    categoryCode: PropTypes.number,
    categoryName: PropTypes.string,
    courseName: PropTypes.string,
    description: PropTypes.string,
    coursePhoto: PropTypes.string,
    questions: PropTypes.string,
    recordStatus: PropTypes.number,
  }),
};

export default AddCourse;
