import axios from "axios";
import { BASE_URL, API_URLS } from "./configUrls";
import { handleUnauthorizedError } from "store/handleUnauthorizedError";
import api from "./api";
import { toast } from "react-toastify";
import { showNetworkErrorExternally } from "../utils";
axios.defaults.withCredentials = true;

const dataService = axios.create({
  baseURL: BASE_URL,
});

// AuthService methods
export const AuthService = {
  login: async (email, opType, captcha, sessionCaptcha) => {
    try {
      const response = await dataService.post(API_URLS.LOGIN, {
        email,
        opType,
        captcha,
        sessionCaptcha,
      });
      return response; // Optionally return response data
    } catch (error) {
      throw error;
    }
  },
  loginVerify: async (email, otp, opType) => {
    try {
      const response = await dataService.post(API_URLS.LOGIN, {
        email,
        otp,
        opType,
      });
      return response; // Optionally return response data
    } catch (error) {
      throw error;
    }
  },
  logout: async () => {
    try {
      const response = await api.get(API_URLS.LOGOUT);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      handleUnauthorizedError(error);
      throw error;
    }
  },
  getRole: async () => {
    try {
      const response = await api.get(API_URLS.GET_ROLE);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      // handleUnauthorizedError(error);
      throw error;
    }
  },
};
export const UserService = {
  getProfile: async () => {
    try {
      const response = await api.get(API_URLS.getProfile);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  getCourseForUser: async () => {
    try {
      const response = await api.post(API_URLS.getCourseForUser);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  getModuleForUser: async (data) => {
    try {
      const response = await api.post(API_URLS.getModuleForUser, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  getModule: async (data) => {
    try {
      const response = await api.post(API_URLS.getModule, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  getModuleForAdd: async (moduleCode) => {
    try {
      const response = await api.get(`${API_URLS.getModuleForAdd}/${moduleCode}`);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  AddDocuments: async (data) => {
    try {
      const response = await api.post(API_URLS.AddDocuments, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  progress: async (data) => {
    try {
      const response = await api.post(API_URLS.Progress, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  score: async (data) => {
    try {
      const response = await api.post(API_URLS.Marks, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  createUser: async (data) => {
    try {
      const response = await api.post(API_URLS.createUser, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  getGenCertificate: async (data) => {
    try {
      const response = await api.post(API_URLS.getGenCertificate, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
};
export const AdminService = {
  course: async (data) => {
    try {
      const response = await api.post(API_URLS.COURSE, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  getCategoryDropdown: async () => {
    try {
      const response = await api.get(API_URLS.CategoryDropdown);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  moduleForUser: async (data) => {
    try {
      const response = await api.post(API_URLS.ModuleForUser, data);
      return response; // Optionally return response data
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  module: async (data) => {
    try {
      const response = await api.post(API_URLS.Module, data);
      return response;
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },

  certificate: async (data) => {
    try {
      const response = await api.post(API_URLS.Certificate, data);
      return response;
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
  category: async (data) => {
    try {
      const response = await api.post(API_URLS.Category, data);
      return response;
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        showNetworkErrorExternally(); // Show the network error modal
      }
      throw error;
    }
  },
};

// toast..................
export function handleSuccessMessage(message) {
  toast.success(message, {
    autoClose: 2000,
    position: "top-center",
  });
}

export function handleErrMessage(message) {
  toast.error(message, {
    autoClose: 2000,
    position: "top-center",
  });
}

export function handleWarnMessage(message) {
  toast.warn(message, {
    autoClose: 2000,
    position: "top-center",
  });
}
