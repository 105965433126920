import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const ContentSection = ({ content }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      mb: 4,
      background: "linear-gradient(to right, #F8F9FA, #FFFFFF)",
      borderLeft: "4px solid #1976D2",
      borderRadius: "4px",
    }}
  >
    <Typography
      variant="body1"
      sx={{
        lineHeight: 1.8,
        color: "#37474F",
        fontSize: "1.1rem",
        textAlign: "justify",
        "& > p": { mb: 2 },
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      }}
    >
      {content}
    </Typography>
  </Paper>
);

const StyledSection = ({ section }) => (
  <Box sx={{ mb: 3 }}>
    <Paper
      elevation={0}
      sx={{
        p: 2,
        background: "linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%)",
        borderRadius: "10px",
        mb: 2,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "#1565C0",
          textShadow: "1px 1px 1px rgba(0,0,0,0.1)",
          letterSpacing: "0.5px",
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: -8,
            left: 0,
            width: "60px",
            height: "3px",
            backgroundColor: "#1976D2",
            borderRadius: "2px",
          },
        }}
      >
        {section.title}
      </Typography>
    </Paper>
    <SplitDescription description={section.description} />
  </Box>
);

const SplitDescription = ({ description }) => {
  const containsHTML = /<[^>]*>/g.test(description);

  if (containsHTML) {
    return (
      <Box sx={{ "& > *": { mb: 4 } }}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Box>
    );
  }

  const sentences = description
    .split(".")
    .filter((s) => s.trim())
    .map((s) => s.trim());

  return sentences.length <= 1 ? (
    <Typography variant="body1" color="text.secondary">
      {description}
    </Typography>
  ) : (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {sentences.map((sentence, i) => (
        <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ mr: 1, color: "primary.main" }}>
            •
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500, color: "text.secondary" }}>
            {sentence}.
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export { ContentSection, StyledSection };
