import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Autocomplete,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Container,
  Modal,
  Paper,
  IconButton,
  Divider,
  Stack,
  Grid,
  CardMedia,
  CardActions,
  Snackbar,
  Alert,
  Fade,
} from "@mui/material";
import {
  Add as AddIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import MDButton from "components/MDButton";
import { BASE_URL } from "../../services/configUrls";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const initialSectionState = {
  title: "",
  description: "",
  image: null,
  imagePreview: "",
  caption: "",
  pageNumber: "",
};

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "color",
  "background",
  "link",
  "image",
];

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  maxHeight: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
};

const ModuleSectionAdd = () => {
  const [selectedModule, setSelectedModule] = useState(null);
  const [modules, setModules] = useState([]);
  const [sections, setSections] = useState([]);
  const [availableSections, setAvailableSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentSection, setCurrentSection] = useState(initialSectionState);
  const [editingIndex, setEditingIndex] = useState(null);
  const [previewSection, setPreviewSection] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${BASE_URL}/auth/get_allcourses`);
        if (!response.ok) throw new Error("Failed to fetch courses");
        const data = await response.json();
        // Ensure we're setting an array
        const coursesArray = Array.isArray(data) ? data : data.courses || [];
        console.log("Courses data:", coursesArray.data); // For debugging
        setCourses(data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setCourses([]);
      }
    };

    fetchCourses();
  }, []);

  // Fetch modules on component mount
  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await fetch(`${BASE_URL}/auth/get_modules/${selectedCourse.course_code}`);
        if (!response.ok) throw new Error("Failed to fetch modules");
        const data = await response.json();
        console.log(data);
        setModules(data);
      } catch (error) {
        console.error("Error fetching modules:", error);
        setModules([]);
      }
    };

    if (selectedCourse) {
      fetchModules();
    }
  }, [selectedCourse]);

  // Fetch sections when module is selected
  useEffect(() => {
    const fetchSections = async () => {
      if (selectedModule?.module_code) {
        try {
          const response = await fetch(
            `${BASE_URL}/auth/get_documents/${selectedModule.module_code}`
          );
          if (!response.ok) throw new Error("Failed to fetch sections");
          const data = await response.json();
          setAvailableSections(data);
        } catch (error) {
          console.error("Error fetching sections:", error);
          setAvailableSections([]);
        }
      }
    };

    fetchSections();
  }, [selectedModule]);

  // Load saved data from localStorage
  useEffect(() => {
    try {
      const savedData = localStorage.getItem("moduleSections");
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        if (parsedData && parsedData.module) {
          setSelectedModule(parsedData.module);
          setSections(parsedData.sectionsList || []);
        }
      }
    } catch (error) {
      console.error("Error loading saved data:", error);
      setSelectedModule(null);
      setSections([]);
    }
  }, []);

  const handleCourseSelect = (event, value) => {
    setSelectedCourse(value);
    setSelectedModule(null);
    setSelectedSection(null);
    setSections([]);
    localStorage.removeItem("moduleSections");
  };

  const handleModuleSelect = (event, value) => {
    setSelectedModule(value);
    setSelectedSection(null);
    setSections([]);
    localStorage.removeItem("moduleSections");
  };

  const handleSectionSelect = (event, value) => {
    setSelectedSection(value);
  };

  const handleOpenModal = (index = null) => {
    if (index !== null && index >= 0 && index < sections.length) {
      setCurrentSection(sections[index]);
      setEditingIndex(index);
    } else {
      setCurrentSection(initialSectionState);
      setEditingIndex(null);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentSection(initialSectionState);
    setEditingIndex(null);
  };

  const handleInputChange = (field, value) => {
    setCurrentSection((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentSection((prev) => ({
          ...prev,
          image: file,
          imagePreview: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveSection = () => {
    if (!isFormValid()) return;

    const sectionToSave = {
      ...currentSection,
      id: editingIndex !== null ? sections[editingIndex].id : Date.now(),
    };

    if (editingIndex !== null && editingIndex >= 0 && editingIndex < sections.length) {
      setSections((prev) =>
        prev.map((section, index) => (index === editingIndex ? sectionToSave : section))
      );
    } else {
      setSections((prev) => [...prev, sectionToSave]);
    }
    handleCloseModal();
  };

  const handleRemoveSection = (index) => {
    if (index >= 0 && index < sections.length) {
      setSections((prev) => prev.filter((_, idx) => idx !== index));
    }
  };

  const handlePreview = (section) => {
    setPreviewSection(section);
  };

  const handleClosePreview = () => {
    setPreviewSection(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const isFormValid = () => {
    return Boolean(currentSection.title && currentSection.description && currentSection.pageNumber);
  };

  const handleSubmitAll = async () => {
    if (!selectedModule || !selectedSection || sections.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select a module, section, and add at least one subsection",
        severity: "error",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const submissionData = {
        moduleCode: selectedModule.module_code,
        moduleName: selectedModule.module_name,
        sectionId: selectedSection.id,
        sections: sections.map((section) => ({
          title: section.title,
          description: section.description,
          page_number: section.pageNumber,
          caption: section.caption,
          image_path: section.imagePreview,
        })),
      };

      const response = await fetch(`${BASE_URL}/auth/add_sections/?id=${selectedSection.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submissionData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }

      setSnackbar({
        open: true,
        message: "Sections submitted successfully!",
        severity: "success",
      });

      setSelectedModule(null);
      setSelectedSection(null);
      setSections([]);
      localStorage.removeItem("moduleSections");
    } catch (error) {
      console.error("Error submitting data:", error);
      setSnackbar({
        open: true,
        message: "Failed to submit sections. Please try again.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Card sx={{ p: 2 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Add Sections
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Stack spacing={3}>
              {/* Module Selection */}

              <Autocomplete
                options={courses || []}
                getOptionLabel={(option) =>
                  option ? `${option.course_name || ""} (${option.course_code || ""})` : ""
                }
                value={selectedCourse}
                onChange={handleCourseSelect}
                renderInput={(params) => (
                  <TextField {...params} label="Select Course" variant="outlined" />
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.course_code === value?.course_code || (!option && !value)
                }
                noOptionsText="No courses available"
              />
              {selectedCourse && (
                <Autocomplete
                  options={modules}
                  getOptionLabel={(option) => `${option?.module_name} (${option?.module_code})`}
                  value={selectedModule}
                  onChange={handleModuleSelect}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Module" variant="outlined" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option?.module_code === value?.module_code
                  }
                />
              )}

              {/* Section Selection */}
              {selectedModule && (
                <Autocomplete
                  options={availableSections}
                  getOptionLabel={(option) => option?.title || ""}
                  value={selectedSection}
                  onChange={handleSectionSelect}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Section" variant="outlined" />
                  )}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                />
              )}

              {selectedSection && (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <MDButton
                    variant="contained"
                    color="info"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenModal()}
                  >
                    Add New Section
                  </MDButton>
                  {sections.length > 0 && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleSubmitAll}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit All Sections"}
                    </Button>
                  )}
                </Box>
              )}

              {sections && sections.length > 0 && (
                <Grid container spacing={3}>
                  {sections.map((section, index) => (
                    <Grid item xs={12} sm={6} md={4} key={section.id}>
                      <Card>
                        {section.imagePreview && (
                          <CardMedia
                            component="img"
                            height="140"
                            image={section.imagePreview}
                            alt={section.title}
                            sx={{ objectFit: "cover" }}
                          />
                        )}
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {section.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Page {section.pageNumber}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            {section.description?.substring(0, 100)}
                            {section.description?.length > 100 ? "..." : ""}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button size="small" onClick={() => handlePreview(section)}>
                            Preview
                          </Button>
                          <Button size="small" onClick={() => handleOpenModal(index)}>
                            Edit
                          </Button>
                          <Button
                            size="small"
                            color="error"
                            onClick={() => handleRemoveSection(index)}
                          >
                            Delete
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Stack>
          </CardContent>
        </Card>

        {/* Add/Edit Section Modal */}
        <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
          <Fade in={openModal}>
            <Paper sx={modalStyle}>
              <Box sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <Typography variant="h6">
                    {editingIndex !== null ? "Edit Section" : "Add New Section"}
                  </Typography>
                  <IconButton onClick={handleCloseModal} size="small">
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Divider sx={{ mb: 3 }} />

                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Title"
                    value={currentSection.title || ""}
                    onChange={(e) => handleInputChange("title", e.target.value)}
                  />

                  {/* <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Description"
                    value={currentSection.description || ""}
                    onChange={(e) => handleInputChange("description", e.target.value)}
                  /> */}

                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      Description
                    </Typography>
                    <ReactQuill
                      value={currentSection.description || ""}
                      onChange={(content) => handleInputChange("description", content)}
                      modules={quillModules}
                      formats={quillFormats}
                      style={{ height: "200px", marginBottom: "50px" }}
                    />
                  </Box>

                  <Box>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="image-upload"
                      type="file"
                      onChange={handleImageChange}
                    />
                    <label htmlFor="image-upload">
                      <MDButton
                        variant="outlined"
                        color="info"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload Image
                      </MDButton>
                    </label>
                    {currentSection.imagePreview && (
                      <Box sx={{ mt: 2 }}>
                        <img
                          src={currentSection.imagePreview}
                          alt="Preview"
                          style={{ maxWidth: "100%", maxHeight: 200, objectFit: "contain" }}
                        />
                      </Box>
                    )}
                  </Box>

                  <TextField
                    fullWidth
                    label="Caption"
                    value={currentSection.caption || ""}
                    onChange={(e) => handleInputChange("caption", e.target.value)}
                  />

                  <TextField
                    fullWidth
                    label="Page Number"
                    type="number"
                    value={currentSection.pageNumber || ""}
                    onChange={(e) => handleInputChange("pageNumber", e.target.value)}
                  />
                </Stack>

                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
                  <MDButton
                    variant="outlined"
                    color="warning"
                    onClick={handleCloseModal}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="info"
                    onClick={handleSaveSection}
                    disabled={!isFormValid()}
                  >
                    {editingIndex !== null ? "Update" : "Add"} Section
                  </MDButton>
                </Box>
              </Box>
            </Paper>
          </Fade>
        </Modal>

        {/* Preview Modal */}
        <Modal open={Boolean(previewSection)} onClose={handleClosePreview} closeAfterTransition>
          <Fade in={Boolean(previewSection)}>
            <Paper sx={modalStyle}>
              <Box sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <Typography variant="h6">Section Preview</Typography>
                  <IconButton onClick={handleClosePreview} size="small">
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Divider sx={{ mb: 3 }} />

                {previewSection && (
                  <Stack spacing={3}>
                    <Typography variant="h5">{previewSection.title}</Typography>

                    {previewSection.imagePreview && (
                      <Box>
                        <img
                          src={previewSection.imagePreview}
                          alt={previewSection.title}
                          style={{ width: "100%", maxHeight: 300, objectFit: "contain" }}
                        />
                        {previewSection.caption && (
                          <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                            {previewSection.caption}
                          </Typography>
                        )}
                      </Box>
                    )}

                    <div dangerouslySetInnerHTML={{ __html: previewSection.description }} />

                    <Typography variant="body2" color="text.secondary">
                      Page {previewSection.pageNumber}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Paper>
          </Fade>
        </Modal>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </DashboardLayout>
  );
};

export default ModuleSectionAdd;
