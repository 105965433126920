import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Modal,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Container,
  Paper,
  Stack,
  Alert,
  Snackbar,
  Fade,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search,
  DeleteSweep,
} from "@mui/icons-material";
import MDButton from "components/MDButton";
import { BASE_URL } from "../../services/configUrls";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  maxHeight: "90vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
};

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

const SectionsManagement = () => {
  const [sections, setSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewModal, setViewModal] = useState({ open: false, data: null });
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState({
    open: false,
    data: null,
    newImage: null,
    newImagePreview: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({ open: false, id: null });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [selectedSections, setSelectedSections] = useState(new Set());
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    const query = searchQuery.toLowerCase();
    const filtered = sections.filter(
      (section) =>
        section.course_name?.toLowerCase().includes(query) ||
        section.module_name?.toLowerCase().includes(query) ||
        section.title?.toLowerCase().includes(query)
    );
    setFilteredSections(filtered);
  }, [searchQuery, sections]);

  const fetchSections = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/auth/getall_sections`);
      if (!response.ok) throw new Error("Failed to fetch sections");
      const data = await response.json();
      setSections(data.data);
    } catch (error) {
      console.error("Error fetching sections:", error);
      showSnackbar("Failed to fetch sections", "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectSection = (id) => {
    const newSelected = new Set(selectedSections);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelectedSections(newSelected);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = filteredSections.map((section) => section.id);
      setSelectedSections(new Set(allIds));
    } else {
      setSelectedSections(new Set());
    }
  };

  const handleBulkDelete = async () => {
    try {
      const selectedIds = Array.from(selectedSections);
      const response = await fetch(`${BASE_URL}/auth/bulksection_delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: selectedIds }),
      });

      if (!response.ok) throw new Error("Failed to delete sections");

      showSnackbar(`Successfully deleted ${selectedIds.length} sections`, "success");
      setBulkDeleteDialog(false);
      setSelectedSections(new Set());
      await fetchSections();
    } catch (error) {
      console.error("Error deleting sections:", error);
      showSnackbar("Failed to delete sections", "error");
    }
  };

  const handleEdit = async (sectionData) => {
    try {
      const formData = new FormData();
      formData.append("title", sectionData.title);
      formData.append("description", sectionData.description);
      formData.append("caption", sectionData.caption);
      formData.append("page_number", sectionData.page_number);

      if (editModal.newImage) {
        formData.append("image_path", editModal.newImage);
      }

      const response = await fetch(`${BASE_URL}/auth/update_section/${sectionData.id}`, {
        method: "PUT",
        body: formData,
      });

      if (!response.ok) throw new Error("Failed to update section");

      showSnackbar("Section updated successfully", "success");
      setEditModal({ open: false, data: null });
      fetchSections();
    } catch (error) {
      console.error("Error updating section:", error);
      showSnackbar("Failed to update section", "error");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${BASE_URL}/auth/delete_section/${deleteDialog.id}`, {
        method: "DELETE",
      });

      if (!response.ok) throw new Error("Failed to delete section");

      showSnackbar("Section deleted successfully", "success");
      setDeleteDialog({ open: false, id: null });
      fetchSections();
    } catch (error) {
      console.error("Error deleting section:", error);
      showSnackbar("Failed to delete section", "error");
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom component="h2">
            Manage Sections
          </Typography>
          <Paper
            elevation={0}
            sx={{
              p: 0.5,
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: 600,
              borderRadius: 2,
              border: "1px solid #e0e0e0",
              "&:hover": {
                border: "1px solid #1976d2",
              },
            }}
          >
            <TextField
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by course, module, or title..."
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="action" />
                  </InputAdornment>
                ),
                disableUnderline: true,
                sx: { px: 2, py: 0.5 },
              }}
            />
          </Paper>
          <Box
            sx={{
              mb: 4,
              mt: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {selectedSections.size > 0 && (
              <MDButton
                variant="contained"
                color="error"
                startIcon={<DeleteSweep />}
                onClick={() => setBulkDeleteDialog(true)}
              >
                Delete Selected ({selectedSections.size})
              </MDButton>
            )}
          </Box>
        </Box>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : filteredSections.length === 0 ? (
          <Paper sx={{ p: 4, textAlign: "center", backgroundColor: "#f5f5f5" }}>
            <Typography variant="h6" color="text.secondary">
              {sections.length === 0 ? "No sections found" : "No matching sections found"}
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {sections.length === 0
                ? "There are currently no sections available to display."
                : "Try adjusting your search criteria."}
            </Typography>
          </Paper>
        ) : (
          <Grid container spacing={3}>
            {filteredSections.map((section) => (
              <Grid item xs={12} sm={6} md={4} key={section.id}>
                <Card sx={{ position: "relative", p: 2 }}>
                  <Box sx={{ position: "relative" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={selectedSections.has(section.id)}
                          onChange={() => handleSelectSection(section.id)}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              border: "2px solid #1976d2",
                              borderRadius: "4px",
                              width: 24,
                              height: 24,
                              backgroundColor: "white",
                              "&:hover": {
                                borderColor: "#1565c0",
                              },
                            },
                          }}
                        />
                      }
                      sx={{ position: "absolute", top: -18, right: -32, zIndex: 1 }}
                    />
                    {/* {section.image_path && (
                      <CardMedia
                        component="img"
                        height="140"
                        image={section.image_path}
                        alt={section.title}
                      />
                    )} */}
                  </Box>
                  {section.image_path && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={section.image_path}
                      alt={section.title}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {section.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Course: {section.course_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Module: {section.module_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Page: {section.page_number}
                    </Typography>

                    <Stack direction="row" spacing={1} mt={2}>
                      <MDButton
                        variant="outlined"
                        color="info"
                        size="small"
                        startIcon={<VisibilityIcon />}
                        onClick={() => setViewModal({ open: true, data: section })}
                      >
                        View
                      </MDButton>
                      <MDButton
                        variant="outlined"
                        color="warning"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => setEditModal({ open: true, data: section })}
                      >
                        Edit
                      </MDButton>
                      <MDButton
                        variant="outlined"
                        color="error"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => setDeleteDialog({ open: true, id: section.id })}
                      >
                        Delete
                      </MDButton>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {/* View Modal */}
        <Modal open={viewModal.open} onClose={() => setViewModal({ open: false, data: null })}>
          <Paper sx={modalStyle}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}
            >
              <Typography variant="h6">View Section</Typography>
              <IconButton onClick={() => setViewModal({ open: false, data: null })}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ mb: 3 }} />
            {viewModal.data && (
              <Stack spacing={2}>
                {viewModal.data.image_path && (
                  <img
                    src={viewModal.data.image_path}
                    alt={viewModal.data.title}
                    style={{ maxWidth: "100%", maxHeight: "300px", objectFit: "contain" }}
                  />
                )}
                <Typography variant="h5">{viewModal.data.title}</Typography>
                <Typography>
                  <strong>Course:</strong> {viewModal.data.course_name}
                </Typography>
                <Typography>
                  <strong>Module:</strong> {viewModal.data.module_name}
                </Typography>
                <Typography>
                  <strong>Description:</strong>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: viewModal.data.description }} />
                <Typography>
                  <strong>Caption:</strong> {viewModal.data.caption}
                </Typography>
                <Typography>
                  <strong>Page Number:</strong> {viewModal.data.page_number}
                </Typography>
              </Stack>
            )}
          </Paper>
        </Modal>

        {/* Edit Modal */}
        <Modal open={editModal.open} onClose={() => setEditModal({ open: false, data: null })}>
          <Paper sx={modalStyle}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}
            >
              <Typography variant="h6">Edit Section</Typography>
              <IconButton onClick={() => setEditModal({ open: false, data: null })}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ mb: 3 }} />
            {editModal.data && (
              <Stack spacing={3}>
                <TextField disabled label="Course" value={editModal.data.course_name} fullWidth />
                <TextField disabled label="Module" value={editModal.data.module_name} fullWidth />
                <TextField
                  label="Title"
                  value={editModal.data.title}
                  onChange={(e) =>
                    setEditModal({
                      ...editModal,
                      data: { ...editModal.data, title: e.target.value },
                    })
                  }
                  fullWidth
                />
                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Description
                  </Typography>
                  <ReactQuill
                    value={editModal.data.description}
                    onChange={(content) =>
                      setEditModal({
                        ...editModal,
                        data: { ...editModal.data, description: content },
                      })
                    }
                    modules={quillModules}
                  />
                </Box>
                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Update Image
                  </Typography>
                  <input
                    accept="image/jpeg,image/png"
                    style={{ display: "none" }}
                    id="image-upload"
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        // Validate file type
                        if (!["image/jpeg", "image/png"].includes(file.type)) {
                          showSnackbar("Please upload only JPEG or PNG images", "error");
                          return;
                        }

                        // Create preview
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setEditModal({
                            ...editModal,
                            newImage: file,
                            newImagePreview: reader.result,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                  <label htmlFor="image-upload">
                    <MDButton variant="outlined" color="info" component="span" sx={{ mb: 2 }}>
                      Choose New Image
                    </MDButton>
                  </label>
                  {(editModal.newImagePreview || editModal.data.image_path) && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Image Preview
                      </Typography>
                      <img
                        src={editModal.newImagePreview || editModal.data.image_path}
                        alt="Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                          objectFit: "contain",
                          display: "block",
                          marginBottom: "8px",
                        }}
                      />
                      {editModal.newImagePreview && (
                        <MDButton
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() =>
                            setEditModal({
                              ...editModal,
                              newImage: null,
                              newImagePreview: null,
                            })
                          }
                        >
                          Remove New Image
                        </MDButton>
                      )}
                    </Box>
                  )}
                </Box>

                <TextField
                  label="Caption"
                  value={editModal.data.caption}
                  onChange={(e) =>
                    setEditModal({
                      ...editModal,
                      data: { ...editModal.data, caption: e.target.value },
                    })
                  }
                  fullWidth
                />
                <TextField
                  label="Page Number"
                  type="number"
                  value={editModal.data.page_number}
                  onChange={(e) =>
                    setEditModal({
                      ...editModal,
                      data: { ...editModal.data, page_number: e.target.value },
                    })
                  }
                  fullWidth
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
                  <MDButton
                    variant="outlined"
                    color="error"
                    onClick={() => setEditModal({ open: false, data: null })}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="info"
                    onClick={() => handleEdit(editModal.data)}
                  >
                    Save Changes
                  </MDButton>
                </Box>
              </Stack>
            )}
          </Paper>
        </Modal>

        {/* Delete Confirmation Dialog */}
        <Dialog open={deleteDialog.open} onClose={() => setDeleteDialog({ open: false, id: null })}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this section? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="outlined"
              color="info"
              onClick={() => setDeleteDialog({ open: false, id: null })}
            >
              Cancel
            </MDButton>
            <MDButton variant="contained" color="error" onClick={handleDelete}>
              Delete
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog open={bulkDeleteDialog} onClose={() => setBulkDeleteDialog(false)}>
          <DialogTitle>Confirm Bulk Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete {selectedSections.size} sections? This action cannot
              be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <MDButton variant="outlined" color="info" onClick={() => setBulkDeleteDialog(false)}>
              Cancel
            </MDButton>
            <MDButton variant="contained" color="error" onClick={handleBulkDelete}>
              Delete
            </MDButton>
          </DialogActions>
        </Dialog>

        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </DashboardLayout>
  );
};

export default SectionsManagement;
