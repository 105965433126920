// Santanu Garnaik Design React layouts
import Dashboard from "layouts/dashboard";

import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";

import Course from "layouts/course";
import AddCourse from "layouts/addCourse";
import DashboardStudent from "layouts/dashboardStudent";
import CourseModule from "layouts/courseModule";
import AddCategory from "layouts/addCategory";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// import CertificateDownload from "layouts/courseModule/CertificateDownload";
import Students from "layouts/students";
import AddStudents from "layouts/students/addStudents";
import Certificates from "layouts/Certificates";
import CertificateDownload from "layouts/Certificates/CertificateDownload";
import ModuleAdd from "./layouts/ModuleAdd/ModuleAdd";
import ModuleSectionAdd from "./layouts/ModuleAdd/ModuleSectionAdd";
import ModuleTable from "./layouts/ModuleSecTable/ModuleTable";
import ModuleDocuments from "./layouts/ModuleAdd/ModuleDocument";
import SectionsManagement from "./layouts/ModuleAdd/SectionManagement";

export const transformModulesToRoutes = (modules) => {
  const moduleRoutes = modules.map((module, index) => {
    const isCompleted = module.isModuleCompleted === 1;

    // Determine accessibility based on completion status of the previous module
    let isAccessible = index === 0 || (index > 0 && modules[index - 1].isModuleCompleted === 1);

    console.log(`Module ${module.moduleName} (Index: ${index}):`);
    console.log(
      ` - Previous Module Completed: ${
        index > 0 ? modules[index - 1].isModuleCompleted === 1 : "N/A"
      }`
    );
    console.log(` - Is Accessible: ${isAccessible}`);

    return {
      type: "collapse",
      name: module.moduleName || `Module ${index + 1}`,
      icon: isCompleted ? "check_circle" : !isAccessible ? "lock" : "play_circle_outline",
      key: `module/${module.moduleCode || index + 1}`, // Use index as key if moduleCode is undefined
      route: `/module/${module.moduleCode || index + 1}`, // Use index as route if moduleCode is undefined
      component: <CourseModule />,
      showInSidebar: true,
      disabled: !isAccessible, // Disable if not accessible
    };
  });

  const dashboardRoute = {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <DashboardStudent />,
    showInSidebar: modules.length === 0, // Show if there are no modules
  };

  const courseRoute = {
    type: "collapse",
    name: "Course",
    key: "course",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/course",
    component: <CourseModule />,
    showInSidebar: false,
  };
  const certificatesRoute = {
    type: "collapse",
    name: "Certificates",
    key: "certificates",
    icon: <Icon fontSize="small">file_copy</Icon>,
    route: "/certificates",
    component: <Certificates />,
    showInSidebar: false,
  };
  const downloadRoute = {
    type: "collapse",
    name: "Download",
    key: "/certificates/download",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/certificates/download",
    component: <CertificateDownload />,
    showInSidebar: false,
  };

  return [dashboardRoute, courseRoute, downloadRoute, certificatesRoute, ...moduleRoutes];
};

export const studentRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <DashboardStudent />,
    showInSidebar: true,
  },
  {
    type: "collapse",
    name: "Certificates",
    key: "certificates",
    icon: <Icon fontSize="small">file_copy</Icon>,
    route: "/certificates",
    component: <Certificates />,
    showInSidebar: true,
  },
  {
    type: "collapse",
    name: "course",
    key: "course",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/course",
    component: <CourseModule />,
    showInSidebar: false,
  },
  {
    type: "collapse",
    name: "Download",
    key: "/certificates/download",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/certificates/download",
    component: <CertificateDownload />,
    showInSidebar: false,
  },
];

export const courseModule = [
  {
    type: "collapse",
    name: "Intro",
    key: "intro",
    icon: <Icon fontSize="small">play_circle_outline</Icon>,
    route: "/intro",
    component: <CourseModule />,
    showInSidebar: true,
    isCompleted: true,
  },
];

const routes = [
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/sign-in",
  //   component: <SignIn />,
  // },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    showInSidebar: true,
  },
  // {
  //   type: "collapse",
  //   name: "Upload Details",
  //   key: "upload-details",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/upload-details",
  //   component: <Tables />,
  // },
  {
    type: "collapse",
    name: "Add Category",
    key: "add-category",
    icon: <Icon fontSize="small">note_add</Icon>,
    route: "/add-category",
    component: <AddCategory />,
    showInSidebar: true,
  },
  {
    type: "collapse",
    name: "Courses",
    key: "courses",
    icon: <Icon fontSize="small">library_books</Icon>,
    route: "/courses",
    component: <Course />,
    showInSidebar: true,
  },
  {
    type: "collapse",
    name: "course",
    key: "course",
    // icon: <Icon fontSize="small">note_add</Icon>,
    route: "/courses/course",
    component: <AddCourse />,
    showInSidebar: false,
  },
  {
    type: "collapse",
    name: "Add Module",
    key: "course",
    icon: <Icon fontSize="small">add_to_photos</Icon>,
    route: "/courses/add-module",
    component: <ModuleAdd />,
    showInSidebar: true,
  },
  {
    type: "collapse",
    name: "Add Sections",
    key: "course",
    icon: <Icon fontSize="small">add_box</Icon>,
    route: "/courses/add-section",
    component: <ModuleSectionAdd />,
    showInSidebar: true,
  },

  {
    type: "collapse",
    name: "Module Documents",
    key: "course",
    icon: <Icon fontSize="small">table</Icon>,
    route: "/documents",
    component: <ModuleDocuments />,
    showInSidebar: true,
  },
  {
    type: "collapse",
    name: "Module Sections",
    key: "course",
    icon: <Icon fontSize="small">preview</Icon>,
    route: "/sections",
    component: <SectionsManagement />,
    showInSidebar: true,
  },

  {
    type: "collapse",
    name: "Add Students",
    key: "add-students",
    icon: <Icon fontSize="small">switch_account</Icon>,
    route: "/add-students",
    component: <AddStudents />,
    showInSidebar: true,
  },
];

export const nonAuthRoutes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    component: <SignIn />,
  },
];

export default routes;
